import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import UserRoute from "routes/UserRoute";
import AdminRoute from "routes/AdminRoute";
import appRoutes from "appRoutes";

import Dashboard from "scenes/Dashboard";
import Profile from "scenes/Profile";
import ProfilePasswordChange from "scenes/ProfilePasswordChange";
import DeletingAccount from "scenes/Profile/DeletingAccount";
import Plan from "scenes/Plan";
import PlanDetails from "scenes/Plan/components/PlanDetails";
import PlanFreeDetails from "scenes/Plan/components/PlanFreeDetails";
import PlanDemo from "scenes/Plan/components/DemoPlans";
import PlanPaymentEnd from "scenes/Plan/components/PlanPaymentEnd";
import Surveys from "scenes/Surveys";
import SurveysCategoryView from "scenes/SurveysCategoryView";
import {default as AdminSurveys} from "scenes/Admin/Surveys";
import RespondentBaseChoose from "scenes/RespondentBaseChoose";
import RespondentBases from "scenes/RespondentBases";
import RespondentBasesSettings from "scenes/RespondentBases/components/Settings/RespondentBasesSettings";
import Addresses from "scenes/Addresses";
import Group from "scenes/Group";
import GroupInvitationAccept from "scenes/Group/components/GroupInvitationAccept";
import SurveysReports from "scenes/Surveys/components/SurveysReports";
import Clients from "scenes/Clients";
import Smtps from "scenes/Smtps";
import ChangeEmailActivation from "scenes/ChangeEmailActivation";
import ChangeEmailCancel from "scenes/ChangeEmailCancel";
import PropTypes from "prop-types";
import Respondents from "scenes/Admin/Respondents";
import Companies from "scenes/Admin/Companies";
import Admins from "scenes/Admin/Admins";
import Points from "scenes/Admin/Points";
import Plans from "scenes/Admin/Plans";
import PlanEdit from "scenes/Admin/Plans/components/PlanEdit";
import PlanAdd from "scenes/Admin/Plans/components/PlanAdd";
import PlanCategories from "scenes/Admin/PlanCategories/PlanCategories";
import Prizes from "scenes/Admin/Prizes";
import RespondentsStats from "scenes/Admin/RespondentsStats";
import CompaniesStats from "scenes/Admin/CompaniesStats";
import PrizesStats from "scenes/Admin/PrizesStats";
import SurveyResponsesStats from "scenes/Admin/SurveyResponsesStats";
import SurveyPointsStats from "scenes/Admin/SurveyPointsStats";
import SurveysStats from "scenes/Admin/SurveysStats";
import PrizeEdit from "scenes/Admin/Prizes/components/PrizeEdit";
import PrizeCreate from "scenes/Admin/Prizes/components/PrizeCreate";
import PrizeCategories from "scenes/Admin/PrizeCategories";
import PrizeCategoryEdit from "scenes/Admin/PrizeCategories/components/PrizeCategoryEdit";
import PrizeCategoryCreate from "scenes/Admin/PrizeCategories/components/PrizeCategoryCreate";
import UserPrizes from "scenes/Admin/UserPrizes";
import Codes from "scenes/Admin/Codes";
import SurveyTemplates from "scenes/Admin/SurveyTemplates";
import Reports from "scenes/Admin/Reports";
import ReportDownloads from "scenes/Admin/ReportDownloads";
import News from "scenes/Admin/News";
import Configurations from "scenes/Admin/Configurations";
import UserEmailDomainBlacklistView from "scenes/Admin/UserEmailDomainBlacklist/UserEmailDomainBlacklistView";
import Footer from "components/Footer";
import Responses from "scenes/Admin/SurveyResponses";
import SurveyCollectorWaitingAccept from "scenes/Admin/SurveyCollectorWaitingAccept";
import SurveysAccepted from "scenes/Admin/SurveysAccepted";
import PanelShipping from "scenes/Admin/PanelShipping";
import EmailShipping from "scenes/Admin/EmailShipping";
import PlansTransactions from "scenes/Admin/PlansTransactions";
import ApplicationFiles from "scenes/Admin/ApplicationFiles/ApplicationFiles";
import SitemapsFiles from "scenes/Admin/SitemapsFiles/SitemapsFiles";
import TopSurveysRanks from "scenes/Admin/TopSurveysRanks";
import SocialRanks from "scenes/Admin/SocialRanks";
import AnkieteoCms from "scenes/Admin/AnkieteoCms";
import SwresearchCms from "scenes/Admin/SwresearchCms";
import DashboardNotifiesCms from "scenes/Admin/DashboardNotifiesCms";
import Header from "../../components/Header";
import {connect} from "react-redux";
import RespondentSmsBases from "scenes/RespondentSms/Bases"
import RespondentSmsBase from "scenes/RespondentSms/Base"

import DictionariesView from "../../views/DictionariesView"
import DictionaryWordsView from "../../views/DictionaryWordsView"
import DictionaryWordSimilarsView from "../../views/DictionaryWordSimilarsView"

import NotificationsView from "../../views/NotificationsView"

import SurveySwitcherCheckView from "../../views/SurveySwitcherCheckView"

import QuestionsResultView from "../../views/QuestionsResultView"
import SurveySendEmailStatsView from "../../views/SurveySendEmailStatsView"
import CintView from "../../views/CintView"
import NotFound from "../../components/NotFound";
import DashboardNotifies from "../../componentsNew/DashboardNotifies/DashboardNotifies";
import FindShipping from "../../scenes/Admin/FindShipping/FindShipping";
import ApiKeys from "../../componentsNew/ApiKeys/apiKeys";
import CaseStudies from "../../scenes/Admin/CaseStudies/caseStudies";
import UserLogins from "../../scenes/Admin/UserLogins/components/UserLogins";
import MediaInfos from "../../scenes/Admin/MediaInfo/mediaInfos";

class DefaultContainer extends Component {
  componentDidMount() {
    document.title = 'Ankieteo - Dashboard - zarządzanie projektami ';
    document.querySelector('meta[name="description"]').setAttribute("content", 'Strefa zarządzania projektami w systemie ankietowym Ankieteo. Twórz, zarządzaj, współdziel swoje projekty badawcze.');
    document.querySelector('meta[name="keywords"]').setAttribute("content", 'Dashboard');
  }

  render() {
    const {isAuthenticated, isAdmin, location} = this.props;

    return <React.Fragment>
      <div className="d-grid" style={{height: "100vh", gridTemplateRows: "auto 1fr auto"}}>
        {isAuthenticated && <Header location={location}/>}

        <div id="dashboard" style={{overflowY: "scroll", overflowX: "hidden"}}>
          {isAuthenticated && !isAdmin && <DashboardNotifies />}

          <Switch>
            <UserRoute location={location} path={appRoutes.home} exact component={Dashboard}/>
            <UserRoute location={location} path={appRoutes.users.deletingAccount} component={DeletingAccount}/>
            <UserRoute location={location} path={appRoutes.users.apiKeys} component={ApiKeys}/>
            <UserRoute location={location} path={appRoutes.users.passwordChangeRequired} component={ProfilePasswordChange}/>
            <UserRoute location={location} path={appRoutes.users.plan} exact component={Plan}/>
            <UserRoute location={location} path={appRoutes.users.freePlanDetails()} exact component={PlanFreeDetails}/>
            <UserRoute location={location} path={appRoutes.users.planDetails()} exact component={PlanDetails}/>
            <UserRoute location={location} path={appRoutes.users.planDemo} exact component={PlanDemo}/>
            <UserRoute location={location} path={appRoutes.users.planPaymentEnd} exact component={PlanPaymentEnd}/>
            <UserRoute location={location} path={appRoutes.surveys.filteredList()} exact component={Surveys}/>
            <UserRoute location={location} path={appRoutes.surveys.list} exact component={Surveys}/>
            <UserRoute location={location} path={appRoutes.surveys.listByCategory} exact component={SurveysCategoryView}/>
            <UserRoute location={location} path={appRoutes.respondentBaseChoose} exact component={RespondentBaseChoose}/>
            <UserRoute location={location} path={appRoutes.respondentBase.list} exact component={RespondentBases}/>
            <UserRoute location={location} path={appRoutes.respondentBase.settings} exact component={RespondentBasesSettings}/>
            <UserRoute location={location} path={appRoutes.respondentBase.addresses()} exact component={Addresses}/>
            <UserRoute location={location} path={appRoutes.respondentSmsBase.list} exact component={RespondentSmsBases}/>
            <UserRoute location={location} path={appRoutes.respondentSmsBase.base()} exact component={RespondentSmsBase}/>
            <UserRoute location={location} path={appRoutes.users.groupInvitationAccept()} exact component={GroupInvitationAccept}/>
            <UserRoute location={location} path={appRoutes.users.group} exact component={Group}/>
            <UserRoute location={location} path={appRoutes.surveys.reports} component={SurveysReports}/>
            <UserRoute location={location} path={appRoutes.clients.list} component={Clients}/>
            <UserRoute location={location} path={appRoutes.smtps.list} component={Smtps}/>
            <UserRoute location={location} path={appRoutes.changeEmail.cancel()} component={ChangeEmailCancel}/>
            <UserRoute location={location} path={appRoutes.changeEmail.activation()} component={ChangeEmailActivation}/>

            <UserRoute location={location} exact path={appRoutes.dictionaries.list} component={DictionariesView}/>
            <UserRoute location={location} exact path={appRoutes.dictionaries.dictionary.wordsList()} component={DictionaryWordsView}/>
            <UserRoute location={location} exact path={appRoutes.dictionaries.dictionary.word.similarList()} component={DictionaryWordSimilarsView}/>

            <UserRoute location={location} exact path={appRoutes.surveySwitcherChecks.list} component={SurveySwitcherCheckView}/>


            <UserRoute location={location} exact path={appRoutes.surveySendEmailStats} component={SurveySendEmailStatsView}/>
            <UserRoute location={location} exact path={appRoutes.questionsResult.list} component={QuestionsResultView}/>
            <UserRoute location={location} exact path={appRoutes.cint} component={CintView}/>

            <UserRoute location={location} exact path={appRoutes.notifications} component={NotificationsView}/>


            <AdminRoute location={location} path={appRoutes.admin.users.respondents} exact component={Respondents}/>
            <AdminRoute location={location} path={appRoutes.admin.users.companies} exact component={Companies}/>
            <AdminRoute location={location} path={appRoutes.admin.users.admins} component={Admins}/>
            <AdminRoute location={location} path={appRoutes.admin.users.logins} component={UserLogins}/>
            <AdminRoute location={location} path={appRoutes.admin.users.points} component={Points}/>
            <AdminRoute location={location} path={appRoutes.admin.surveys.list} exact component={AdminSurveys}/>
            <AdminRoute location={location} path={appRoutes.admin.surveys.waitingAccept} exact component={SurveyCollectorWaitingAccept}/>
            <AdminRoute location={location} path={appRoutes.admin.surveys.accepted} exact component={SurveysAccepted}/>
            <AdminRoute location={location} path={appRoutes.admin.surveys.responses} exact component={Responses}/>
            <AdminRoute location={location} path={appRoutes.admin.prizes.list} exact component={Prizes}/>
            <AdminRoute location={location} path={appRoutes.admin.prizes.create} exact component={PrizeCreate}/>
            <AdminRoute location={location} path={appRoutes.admin.prizes.edit()} exact component={PrizeEdit}/>
            <AdminRoute location={location} path={appRoutes.admin.prizes.categories.list} exact component={PrizeCategories}/>
            <AdminRoute location={location} path={appRoutes.admin.prizes.categories.create} exact component={PrizeCategoryCreate}/>
            <AdminRoute location={location} path={appRoutes.admin.prizes.categories.edit()} exact component={PrizeCategoryEdit}/>
            <AdminRoute location={location} path={appRoutes.admin.prizes.userPrizes} exact component={UserPrizes}/>
            <AdminRoute location={location} path={appRoutes.admin.cms.reports} exact component={Reports}/>
            <AdminRoute location={location} path={appRoutes.admin.cms.reportDownloads} exact component={ReportDownloads}/>
            <AdminRoute location={location} path={appRoutes.admin.cms.caseStudies} exact component={CaseStudies}/>
            <AdminRoute location={location} path={appRoutes.admin.cms.mediaInfos} exact component={MediaInfos}/>
            <AdminRoute location={location} path={appRoutes.admin.cms.newses} exact component={News}/>
            <AdminRoute location={location} path={appRoutes.admin.cms.topSurveysRanks} exact component={TopSurveysRanks}/>
            <AdminRoute location={location} path={appRoutes.admin.cms.socialRanks} exact component={SocialRanks}/>
            <AdminRoute location={location} path={appRoutes.admin.cms.ankieteo.main} component={AnkieteoCms}/>
            <AdminRoute location={location} path={appRoutes.admin.cms.swresearch.main} component={SwresearchCms}/>
            <AdminRoute location={location} path={appRoutes.admin.cms.dashboardNotifies.main} component={DashboardNotifiesCms}/>
            <AdminRoute location={location} path={appRoutes.admin.settings.codes} exact component={Codes}/>
            <AdminRoute location={location} path={appRoutes.admin.settings.plans.list} exact component={Plans}/>
            <AdminRoute location={location} path={appRoutes.admin.settings.planCategories.list} exact component={PlanCategories}/>
            <AdminRoute location={location} path={appRoutes.admin.settings.plans.create} exact component={PlanAdd}/>
            <AdminRoute location={location} path={appRoutes.admin.settings.plans.edit()} exact component={PlanEdit}/>
            <AdminRoute location={location} path={appRoutes.admin.settings.configurations} exact component={Configurations}/>
            <AdminRoute location={location} path={appRoutes.admin.settings.userEmailDomainBlacklist} exact component={UserEmailDomainBlacklistView}/>
            <AdminRoute location={location} path={appRoutes.admin.stats.respondents} exact component={RespondentsStats}/>
            <AdminRoute location={location} path={appRoutes.admin.stats.companies} exact component={CompaniesStats}/>
            <AdminRoute location={location} path={appRoutes.admin.stats.prizes} exact component={PrizesStats}/>
            <AdminRoute location={location} path={appRoutes.admin.stats.surveyResponses} exact component={SurveyResponsesStats}/>
            <AdminRoute location={location} path={appRoutes.admin.stats.surveyPoints} exact component={SurveyPointsStats}/>
            <AdminRoute location={location} path={appRoutes.admin.stats.surveys} exact component={SurveysStats}/>
            <AdminRoute location={location} path={appRoutes.admin.surveys.templates} exact component={SurveyTemplates}/>

            <AdminRoute location={location} path={appRoutes.admin.plans.transactions} exact component={PlansTransactions}/>
            <AdminRoute location={location} path={appRoutes.admin.files.files} exact component={ApplicationFiles}/>
            <AdminRoute location={location} path={appRoutes.admin.files.sitemaps} exact component={SitemapsFiles}/>

            <AdminRoute location={location} path={appRoutes.admin.shipping.find} exact component={FindShipping}/>
            <AdminRoute location={location} path={appRoutes.admin.shipping.panel} exact component={PanelShipping}/>
            <AdminRoute location={location} path={appRoutes.admin.shipping.email} exact component={EmailShipping}/>

            <Route chilren={<NotFound />}/>
          </Switch>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  }
}

DefaultContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    isAuthenticated: Object.keys(state.user).length > 0,
    isAdmin: state.user.roles && (state.user.roles.includes('ROLE_SUPER_ADMIN') || state.user.roles.includes('ROLE_ADMIN')),
  }
}

export default connect(mapStateToProps)(DefaultContainer);
