import React, {Component} from 'react';
import {Col, Container, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import Form from "./LoginForm";
import {userLogin} from "reducers/user/actions";
import "dashboard/styles/login.scss"
import {FormattedMessage} from "react-intl";

class Login extends Component {
  constructor(){
    super();

    this.state = {
      deleteModal: true
    };

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    localStorage.removeItem('deleteAt');
    document.title = 'Logowanie | ANKIETEO';
    document.querySelector('meta[name="description"]').setAttribute("content", 'Zaloguj się do Ankieteo | Twórz i zarządzaj projektami badawczymi');
    document.querySelector('meta[name="keywords"]').setAttribute("content", 'ankieteo, ankietheo, ankieta, ankiety, sondaże, ankiety online, wywiady online, kwestionariusz, badania, kreator ankiet, plan, logowanie');
  }

  toggleModalDelete() {
    this.setState({deleteModal: false});
  }

  submit(data, captcha){
    if(localStorage.getItem('redirectAfterLogin')){
      localStorage.removeItem('redirectAfterLogin')
    }
    return this.props.userLogin(data, captcha)
  }

  render() {
    return (
      <Container>
        <Row className="mh-100vh align-items-center justify-content-center">
          <Col md={12} lg={11} className="login-wrap">
            <Form submit={this.submit}/>
          </Col>
        </Row>

        {localStorage.getItem('deleteAt') && <Modal isOpen={this.state.deleteModal} toggle={() => this.toggleModalDelete()}>
          <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2"><FormattedMessage id="login.modalDelete.cardHeader"/></ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            <p><FormattedMessage id="login.modalDelete.cardBody.start"/></p>
            <p><FormattedMessage id="login.modalDelete.cardBody.info.first"/> {localStorage.getItem('deleteAt')}. <FormattedMessage id="login.modalDelete.cardBody.info.second"/></p>
          </ModalBody>
        </Modal>}

      </Container>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(null, { userLogin })(Login);
